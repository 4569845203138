import React from 'react';
import './DefaultPage.css'; // Import CSS file for styling

const DefaultPage = () => {
    return (
        <div className="default-page">
            <h1>Welcome to The Systems!</h1>
            {/* <img src="https://media.discordapp.net/attachments/1206658644494655498/1208191665203052604/file-mVu2S6CdLs6fNWOyJ5BGv5nj.png?ex=65e2632c&is=65cfee2c&hm=ce94237744f99d0c484a68a32b48892c8d42cf10496384422e7f09994b15c769&=&format=webp&quality=lossless&width=671&height=671" alt="Welcome Image" className="welcome-image" /> */}
            <p className="description">
                This is the default page displayed when there is no content available. You can find more information about each section below:
            </p>
            {/* <ul className="section-list">
                <li><strong>Dashboard:</strong> Coming Very Soon</li>
                <li><strong>Raffles:</strong> Coming Soon</li>
                <li><strong>Mint:</strong> Coming Soon</li>
                <li><strong>Sniper:</strong> Under Construction</li>
                <li><strong>Support:</strong> Coming Very Soon</li>
                <li><strong>Settings:</strong> Coming Very Soon</li>
            </ul> */}
        </div>
    );
};

export default DefaultPage;
