import React, { useEffect, useState } from 'react';
import './SideBar.css';
import SystemLogo from '../../assets/SystemLogo.png';

import { faQuestionCircle, faCog, faHome, faTicketAlt, faRobot, faCrosshairs, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SidebarProps {
  sendDataToDashboard: (data: { message: string }) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ sendDataToDashboard }) => {
  const [selectedItem, setSelectedItem] = useState('raffles');
  const [havePermission, setHavePermission] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const wallet = queryParams.get('wallet');
  const [expiredDate, setExpiredDate] = useState('');
  function converterData(dataDate: Date): string {
    if (dataDate) {
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const formattedDate = dataDate.toLocaleString('pt-BR', {
        timeZone: clientTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      return formattedDate;
    }
    return ''
  }
  const checkExpire = async () => {
    try {
      const response = await fetch(`https://the-systems.onrender.com/checkExpire?wallet=${wallet}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setExpiredDate(data.expire);
    } catch (error) {
      console.error("Não foi possível verificar a elegibilidade:", error);
      return false;
    }
  }
  useEffect(() => {
    const verifyWallet = async () => {
      if (wallet) {
        setHavePermission(await checkPermission(wallet))
      } else {
        setHavePermission(false);
      }
    };
    verifyWallet();
    checkExpire()
  }, [wallet]);

  const checkPermission = async (wallet: string) => {
    try {
      const response = await fetch(`https://the-systems.onrender.com/checkPermission?walletRegister=${wallet}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.permission;
    } catch (error) {
      console.error("Não foi possível verificar a elegibilidade:", error);
      return false;
    }
  };


  const handleItemClick = (item: string) => {
    setSelectedItem(item);

    const data = {
      message: '',
    };
    if (item === 'dashboard') data.message = 'dashboard';
    if (item === 'adminPanel') data.message = 'adminPanel';
    if (item === 'raffles') data.message = 'raffles';
    if (item === 'walletRegister') data.message = 'walletRegister';
    if (item === 'settings') data.message = 'settings';

    sendDataToDashboard(data);

  };

  return (
    <div className="sidebar">
      <div className="logo">
        <img src={SystemLogo} alt="Logo" className="logoImg" />
        <h1>The Systems</h1>
      </div>
      <div className="divisor"></div>
      <div style={{ color: 'white', marginTop: '1vh' }}>
        Vencimento: {converterData(new Date(expiredDate))}
      </div>
      <ul className="menu-sidebar">
        {havePermission && <li>
          <button
            className={selectedItem === 'adminPanel' ? 'menu-item selected' : 'menu-item'}
            onClick={() => handleItemClick('adminPanel')}
          >
            <FontAwesomeIcon icon={faHome} className="icon-sidebar" />
            Admin Panel
          </button>
        </li>}
        <li>
          <button
            className={selectedItem === 'raffles' ? 'menu-item selected' : 'menu-item'}
            onClick={() => handleItemClick('raffles')}
          >
            <FontAwesomeIcon icon={faTicketAlt} className="icon-sidebar" />
            Raffles
          </button>
        </li>
        <li>
          <button
            className={selectedItem === 'walletRegister' ? 'menu-item selected' : 'menu-item'}
            onClick={() => handleItemClick('walletRegister')}
          >
            <FontAwesomeIcon icon={faWallet} className="icon-sidebar" />
            Wallet Register
          </button>
        </li>
        <li>
          <button
            className={selectedItem === 'settings' ? 'menu-item selected' : 'menu-item'}
            onClick={() => handleItemClick('settings')}
          >
            <FontAwesomeIcon icon={faCog} className="icon-sidebar" />
            Settings
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
