import React from 'react';
import './error.css';
import svg from './404.svg';
import { useHistory } from 'react-router-dom';

function ErrorPage() {
    const history = useHistory();

    const goBack = () => {
        history.goBack(); // Navigate back to the previous page
    };

    return (
        <div className="Error">
            <img src={svg} alt="svg" />
            <button onClick={goBack}>Go Back</button>
        </div>
    );
}

export default ErrorPage;
