import React from 'react';
import { Box, Typography, Divider, List, ListItem, Button } from '@mui/material';

interface Wallet {
  name: string;
  wallet: string;
}

interface WalletListProps {
  wallets: Wallet[];
  onDelete: (arg0: Wallet) => void;
}

const WalletRegisterList: React.FC<WalletListProps> = ({ wallets, onDelete }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display: 'flex', flexDirection: 'row', bgcolor: 'transparent', marginLeft: '3vw'}}>
        <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>Nome</Typography>
        <Typography variant="subtitle1" sx={{ width: '36vw', textAlign: 'center' }}>Wallet Address</Typography>
      </Box>
      <Divider />
      <List sx={{ padding: 0, width: '48vw' }}>
        {wallets.map((wallet, index) => (
          <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'transparent', py: 1, marginLeft: '2vw' }}>
            <Typography variant="body1" sx={{ flex: 1, wordBreak: 'break-all' }}>{wallet.name}</Typography>
            <Typography variant="body1" sx={{ flex: 1, wordBreak: 'break-all' }}>{wallet.wallet}</Typography>
            <Box sx={{width: '200px', textAlign: 'center' }}>
              <Button variant="outlined" onClick={() => onDelete(wallet)}>
                Remove
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default WalletRegisterList;
