import React, { useState } from 'react';
import DefaultPage from '../DefaultPage/DefaultPage';
import WalletRegisterPrincipalEVM from './EvmWalletCrud/walletRegisterPrincipalEVM';
import WalletRegisterPrincipalOrdinal from './OrdinalWalletCrud/walletRegisterPrincipalOrdinal';
import WalletRegisterPrincipalSolana from './SolanaWalletCrud/walletRegisterPrincipalSolana';
import WalletRegisterPrincipalSei from './SeiWalletCrud/walletRegisterPrincipalSei';

const WalletRegister = () => {
    const [selectedItem, setSelectedItem] = useState('evm');

    const handleButtonClick = (item: string) => {
        setSelectedItem(item);
    };

    let componentPage: JSX.Element;
    switch (selectedItem) {
        case 'evm':
            componentPage = <WalletRegisterPrincipalEVM />;
            break;
        case 'ordinal':
            componentPage = <WalletRegisterPrincipalOrdinal />;
            break;
        case 'solana':
            componentPage = <WalletRegisterPrincipalSolana />;
            break;
        case 'sei':
            componentPage = <WalletRegisterPrincipalSei />;
            break;
        default:
            componentPage = <DefaultPage />;
            break;
    }

    return (
        <div style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            fontFamily: 'Chillax',
            alignItems: 'center'
        }}>
            <h2>Wallet Register</h2>
            <div className='setting-container'>
                <button className={selectedItem === 'evm' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('evm')}>EVM</button>
                <button className={selectedItem === 'ordinal' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('ordinal')}>Ordinal</button>
                <button className={selectedItem === 'solana' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('solana')}>Solana</button>
                <button className={selectedItem === 'sei' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('sei')}>Sei</button>
            </div>

            <hr style={{ width: '50%', marginTop: '20px', border: '1px solid #313131' }} />


            <div>
                {componentPage}
            </div>
            <h3>
                Please Attention:
            </h3>
            <div>
                1 - To add another protocol, please, open ticket on DISCORD
            </div>
            <div>
                2 - Put correct wallet from protocol, or the raffle not 'll register when system try use this wallet
            </div>
            <div>
                3 - Only put the wallet if it exist on alphabot
            </div>
        </div>
    );
};

export default WalletRegister;