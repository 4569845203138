import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)({
    position: 'absolute',
    width: '100%',
    [`& .${linearProgressClasses.bar}`]: {
        transition: 'none',
    },
});

type NotificationProps = {
    message: string;
    severity: 'success' | 'error' | 'info' | 'warning';
    open: boolean;
    handleClose: () => void;
};

const NotificationComponent: React.FC<NotificationProps> = ({
    message,
    severity,
    open,
    handleClose,
}) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        if (!open) return;

        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress > 0 ? prevProgress - 10 : 0));
        }, 410);

        const timeout = setTimeout(() => {
            handleClose();
        }, 4000);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [open, handleClose]);

    return (
        <Box sx={{ position: 'fixed', top: 50, right: 20, zIndex: 1000, width: '20%' }}>
            <Alert severity={severity}>{message}</Alert>
            <Box sx={{ position: 'relative', width: '100%', height: 10, marginTop: 1 }}>
                <BorderLinearProgress variant="determinate" value={100 - progress} />
            </Box>
        </Box>
    );
};

export default NotificationComponent;