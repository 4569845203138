import React, { FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './alphabotForm.css'
import NotificationComponent from '../../../../components/Notification/Notification';


const AlphabotForm = () => {
    const [formData, setFormData] = useState({
        apiKey: '',
        wallet: '',
        accessToken: ''
    });
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const queryParams = new URLSearchParams(window.location.search);
    const wallet = queryParams.get('wallet');

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const response = await fetch(`https://the-systems.onrender.com/getConfigAlphabot?wallet=${wallet}`);
                const data = await response.json();

                if (response.ok) {
                    setShowNotification(false);
                    if (data.length > 0) {
                        setFormData(data[0]);
                    }
                    else {
                        setFormData(data);
                    }
                } else {
                    setShowNotification(true);
                    setNotification({ message: data.message || 'Network response was not ok', type: 'error' });
                }


            } catch (error) {
                setShowNotification(true);
                setNotification({ message: 'Failed to save configuration', type: 'error' });
            }
        };

        fetchConfigData();
    }, []);


    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowNotification(true);
        try {
            const response = await fetch('https://the-systems.onrender.com/saveConfigAlphabot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ apiKey: formData.apiKey, wallet: wallet}),
            });

            const data = await response.json();

            if (response.ok) {
                setNotification({ message: data.message, type: 'success' });
            } else {
                setNotification({ message: data.message || 'Network response was not ok', type: 'error' });
            }

        } catch (error) {
            setNotification({ message: 'Failed to save configuration', type: 'error' });
        }
    };
    const handleNotificationClose = () => {
        setShowNotification(false);
    };

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        variant='standard'
                        color='warning'
                        focused
                        name="apiKey"
                        label="Api Key"
                        value={formData.apiKey}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        style={{ width: '19vw' }}
                        InputProps={{
                            style: { borderBottomColor: 'white', color: 'white' },
                            type: 'password',
                        }}
                    />
                </div>
            </div>
            <div>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}>
                    <Button type="submit" variant="outlined" sx={{
                        color: '#FF6D03',
                        borderColor: '#FF6D03',
                        ':hover': {
                            borderColor: '#FF6D03',
                            backgroundColor: 'transparent'
                        },
                    }}>
                        Save
                    </Button>
                </Box>
            </div>
            <div className="div informationToken">
                <h1>How to Obtain Your API Key</h1>

                <h2>Obtaining the API Key</h2>
                <ol>
                    <li>Go to the <strong>alphabot</strong> website.</li>
                    <li>Enter your profile.</li>
                    <li>Go to <strong>Developer Settings</strong>.</li>
                    <li>Click on <strong>generate key</strong> to obtain your API Key.</li>
                </ol>
            </div>

            {showNotification && (
                <NotificationComponent
                    message={notification.message}
                    severity={notification.type as 'error' | 'warning' | 'info' | 'success'}
                    open={showNotification}
                    handleClose={handleNotificationClose}
                />
            )}
        </form>

    );
};

export default AlphabotForm;
