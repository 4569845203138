import React, { useState } from 'react';
import './settings.css';
import SniperForm from './sniperForm/sniperForm';
import DefaultPage from '../DefaultPage/DefaultPage';
import RafflesForm from './rafflesForm/rafflesForm';

const Config = () => {
    const [selectedItem, setSelectedItem] = useState('raffles');

    const handleButtonClick = (item: string) => {
        setSelectedItem(item);
    };
    
    let componentPage: JSX.Element;
    switch (selectedItem) {
        case 'raffles':
            componentPage = <RafflesForm />;
            break;
        default:
            componentPage = <DefaultPage />;
            break;
    }

    return (
        <div style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            fontFamily: 'Chillax',
            alignItems: 'center'
        }}>
            <h2>Settings</h2>
            <div className='setting-container'>
                <button className={selectedItem === 'raffles' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('raffles')}>Raffles</button>
            </div>
            <hr style={{ width: '50%', marginTop: '20px', border: '1px solid #313131' }} />

            <div>
                {componentPage}
            </div>
        </div>
    );
};

export default Config;