import React, { useEffect, useState } from 'react';
import './alphabot.css';
import { Button } from '@mui/material';
import FilterDropdown from '../../../components/FilterDropdown/FilterDropdown';
import NotificationComponent from '../../../components/Notification/Notification';

interface IRaffle {
    id: number;
    name: string;
    enterDate: string;
    status: 'Registered' | 'Error' | 'Discord Requirement' | 'Wallet Requirement' | 'Invalid Password' | 'Pending' | 'Premium Raffle (Need Manual) | Won';
    slug: string;
    message: string;
}
window.addEventListener('beforeunload', function (e) {
    sessionStorage.clear();
});

function Alphabot() {
    const [combinedRaffles, setCombinedRaffles] = useState<IRaffle[]>([]);
    const [quantity, setQuantity] = useState(0)
    const [keyApi, setKeyApi] = useState('')
    const [stateStarted, setStateStarted] = useState('Stopped')
    const [filters, setFilters] = useState({})
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const ITEMS_PER_PAGE = 100;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isFilterPagination, setIsFilterPagination] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const walletAddress = queryParams.get('wallet');

    const fetchRaffles = async (apiKey?: any, currentPage?: any) => {
        try {
            let key = keyApi
            if (apiKey) {
                key = apiKey
            }
            const response = await fetch(`https://the-systems.onrender.com/rafflesAlphabot?walletUser=${key}&page=${currentPage}&limit=${ITEMS_PER_PAGE}`);
            const data = await response.json();
            const raffles: IRaffle[] = data.raffles

            if (response.ok) {
                setTotalPages(data.totalPages)
                setQuantity(data.count)
                setCurrentPage(data.currentPage)
                removeDuplicatedFromArray(raffles)
            } else {
                setShowNotification(true);
                setNotification({ message: "Error during get Raffles, Please, contact administrator" || 'Network response was not ok', type: 'error' });
            }

        } catch (error: any) {
            if (error.message) {
                setShowNotification(true);
                setNotification({ message: error.message, type: 'error' });
            }
        }
    };

    const removeDuplicatedFromArray = (array: IRaffle[] | undefined) => {
        if (!array) return;
        const newArray = new Set(array);
        const uniqueArray = Array.from(newArray);

        const ajustarEDefinirDatas = uniqueArray.map(item => {
            const formattedDate = converterData(new Date(item.enterDate))
            return { ...item, enterDate: formattedDate };
        });

        setCombinedRaffles(ajustarEDefinirDatas);
    }

    function converterData(dataDate: Date): string {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const formattedDate = dataDate.toLocaleString('pt-BR', {
            timeZone: clientTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        return formattedDate;
    }

    const fetchConfig = async () => {


        try {
            if (walletAddress) {
                setKeyApi(walletAddress)
                fetchRaffles(walletAddress)
            }
        } catch (error: any) {
            if (error.message) {
                setShowNotification(true);
                setNotification({ message: error.message, type: 'error' });
            }
        }
    };




    const executeRaffles = async (): Promise<IRaffle[]> => {
        try {
            const response = await fetch('https://the-systems.onrender.com/executeAlphabot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ walletUser: walletAddress }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error);
            } else {
                setShowNotification(true);
                setNotification({ message: "Analysis started, you can close this tab!", type: 'success' });
            }

            return data.registerResult || [];
        } catch (error: any) {
            if (error.message) {
                setShowNotification(true);
                setNotification({ message: error.message, type: 'error' });
            }
            return []
        }
    }

    const checkAndRegisterRaffles = async () => {
        let resultExecute = await executeRaffles()
        if (resultExecute && resultExecute.length > 0) {
            fetchRaffles(walletAddress);
        }
    };
    const stopExecution = async () => {
        await stopExecutionRaffles()
        await fetchRaffles(walletAddress);
    };

    const stopExecutionRaffles = async () => {
        try {
            const response = await fetch('https://the-systems.onrender.com/stopInterval', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ walletUser: walletAddress }),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error);
            } else {
                setShowNotification(true);
                setNotification({ message: "Analysis Stoped!", type: 'success' });
            }

            return data.registerResult || [];
        } catch (error: any) {
            if (error.message) {
                setShowNotification(true);
                setNotification({ message: error.message, type: 'error' });
            }
            return []
        }
    }



    const handleNotificationClose = () => {
        setShowNotification(false);
    };

    const handleEnterClick = () => {
        checkAndRegisterRaffles()

        setStateStarted("Analyzing")
    };

    const handleStopClick = () => {
        stopExecution()
    };

    useEffect(() => {
        fetchConfig();
    }, []);

    useEffect(() => {
        setTotalPages(Math.ceil(quantity / ITEMS_PER_PAGE));
    }, [quantity]);

    const handlePageClick = async (pageNumber: number,) => {
        setCurrentPage(pageNumber);
        if (isFilterPagination) {
            await fetchFilterRaffles(pageNumber)
        } else {
            await fetchRaffles(walletAddress, pageNumber)
        }
    };

    const clickedFilter = async (filters: any) => {
        setCurrentPage(1)
        setIsFilterPagination(true)
        await fetchFilterRaffles(1, filters)
    }
    const fetchFilterRaffles = async (currentPage?: any, filter?: any) => {
        try {
            if (!filter) {
                filter = filters
            }
            const url = new URL(`https://the-systems.onrender.com/filterRaffles?walletUser=${walletAddress}&page=${currentPage}`);
            Object.entries(filter).forEach(([key, value]) => {
                url.searchParams.append(key, String(value));
            });
            const response = await fetch(url);
            const data = await response.json();
            const raffles: IRaffle[] = data.results
            if (response.ok) {
                setTotalPages(data.totalPages)
                setQuantity(data.count)
                setCurrentPage(data.currentPage)
                removeDuplicatedFromArray(raffles)
            } else {
                throw new Error(data.message);
            }
        } catch (error: any) {
            if (error.message) {
                setShowNotification(true);
                setNotification({ message: error.message, type: 'error' });
            }
        }
    }
    const clickedClean = async () => {
        setCurrentPage(1)
        await fetchRaffles()
    }

    const renderPagination = (totalPages: any, currentPage: any, handlePageClick: any) => {
        const pageNumbers = [];
        let startPage, endPage;

        if (totalPages <= 6) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 4) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        if (startPage > 1) {
            pageNumbers.push(
                <button key={1} className="page-button" onClick={() => handlePageClick(1)}>
                    1
                </button>
            );
            if (startPage > 2) pageNumbers.push(<span key="start-ellipsis" className="ellipsis">...</span>);
        }

        for (let page = startPage; page <= endPage; page++) {
            pageNumbers.push(
                <button
                    key={page}
                    className={`page-button ${currentPage === page ? 'active' : ''}`}
                    onClick={() => handlePageClick(page)}
                    disabled={currentPage === page}
                >
                    {page}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) pageNumbers.push(<span key="end-ellipsis" className="ellipsis">...</span>);
            pageNumbers.push(
                <button key={totalPages} className="page-button" onClick={() => handlePageClick(totalPages)}>
                    {totalPages}
                </button>
            );
        }

        return (
            <div className="pagination">
                <button
                    className="page-button"
                    onClick={() => handlePageClick(Math.max(1, currentPage - 1))}
                    disabled={currentPage === 1}
                >
                    {'<'}
                </button>
                {pageNumbers}
                <button
                    className="page-button"
                    onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))}
                    disabled={currentPage === totalPages}
                >
                    {'>'}
                </button>
            </div>
        );
    };

    return (
        <div className='RaffleContainer'>
            <div className="filterCentralized">
                <FilterDropdown onFilter={async function (filters: { chain: string[]; status: string[]; search: string; clickedFilter: Boolean }): Promise<void> {
                    setFilters(filters)
                    await clickedFilter(filters)
                }}
                    onCleanFilter={function (filters: { clickedClean: Boolean }): void {
                        setFilters(filters)
                        clickedClean()
                    }} />
            </div>
            <div className="list-container">
                <div className="title-container">
                    <div>Raffle Name</div>
                    <div className="middle-title">Enter Date</div>
                    <div>Status</div>
                </div>
                <hr style={{ width: '95%', marginTop: '20px', border: '1px solid #313131' }} />
                {combinedRaffles?.map((item, index) => (
                    <div key={`${item.slug}-${index}`} className="list-item">
                        <div>
                            <a className="ancoraRedirectAlphabot"
                                target="_blank"
                                href={`https://alphabot.app/${item.slug}`}
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                {item.slug}
                            </a>
                        </div>
                        <div>{item.enterDate}</div>
                        <div className={`${item.status.toLowerCase()}`}>{item.status}</div>
                    </div>
                ))}
            </div>
            <div className="quantityRaffles">
                <div className="pagination-buttons">


                    {renderPagination(totalPages, currentPage, handlePageClick)}

                </div>

            </div>
            <div className="button-start-container">
                <Button
                    type="submit"
                    variant="outlined"
                    onClick={handleEnterClick}
                    sx={{
                        color: '#FF6D03',
                        borderColor: '#FF6D03',
                        marginTop: '-14px',
                        marginLeft: '-3vw',
                        ':hover': {
                            borderColor: '#FF6D03',
                            backgroundColor: 'transparent'
                        },
                    }}>
                    Enter
                </Button>
                <Button
                    type="submit"
                    variant="outlined"
                    onClick={handleStopClick}
                    sx={{
                        color: 'red',
                        borderColor: 'red',
                        marginTop: '-14px',
                        marginLeft: '4px',
                        ':hover': {
                            borderColor: 'red',
                            backgroundColor: 'transparent'
                        },
                    }}>
                    Stop
                </Button>
            </div>
            {showNotification && (
                <NotificationComponent
                    message={notification.message}
                    severity={notification.type as 'error' | 'warning' | 'info' | 'success'}
                    open={showNotification}
                    handleClose={handleNotificationClose}
                />
            )}
        </div>
    );
}
export default Alphabot;