import React, { useEffect } from 'react';
import { TextField, MenuItem, Button, Container, Box, Switch, FormControlLabel, InputLabel } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { IWalletEntry } from '../AdminPage/WalletInterface';

interface WalletFormProps {
    onSubmit: (data: IWalletEntry) => void;
    initialData: IWalletEntry | null;
}

const WalletForm: React.FC<WalletFormProps> = ({ onSubmit, initialData }) => {

    const validationSchema = Yup.object({
        wallet: Yup.string().required('Required'),
        duration: Yup.number().min(1).max(12).required('Required'),
        urlComprove: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
    });
    const initialValues: IWalletEntry = {
        wallet: '',
        duration: 1,
        urlComprove: '',
        description: '',
        approved: false,
        approveCode: '',
        id: '',
        discordName: '',
        renewUser: false
    };

    return (
        <Container maxWidth="sm">
            <Formik
                initialValues={initialData || initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, actions) => {
                    if (initialData) {
                        onSubmit({ ...values, id: initialData.id, approved: initialData.approved });
                    } else {
                        onSubmit({ ...values, id: Date.now().toString(), approved: false });
                    }
                    actions.resetForm();
                }}

            >
                {({ errors, touched }) => (
                    <Form>
                        <Box sx={{ '& .MuiTextField-root': { m: 1 }, mt: 3 }}>
                            <Field as={TextField}
                                name="wallet"
                                label="Wallet Address"
                                fullWidth
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                                helperText={<ErrorMessage name="wallet" />}
                                error={touched.wallet && Boolean(errors.wallet)}
                            />

                            <Field as={TextField}
                                name="discordName"
                                label="Discord Name"
                                fullWidth
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                                helperText={<ErrorMessage name="discordName" />}
                                error={touched.discordName && Boolean(errors.discordName)}
                            />

                            <Field as={TextField}
                                name="duration"
                                label="Duration"
                                select
                                fullWidth
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                                helperText={<ErrorMessage name="duration" />}
                                error={touched.duration && Boolean(errors.duration)}
                            >
                                {Array.from({ length: 12 }, (_, i) => (
                                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                                ))}
                            </Field>

                            <Field as={TextField}
                                name="urlComprove"
                                label="Proof URL"
                                fullWidth
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                                helperText={<ErrorMessage name="urlComprove" />}
                                error={touched.urlComprove && Boolean(errors.urlComprove)}
                            />

                            <Field as={TextField}
                                name="description"
                                label="Description"
                                fullWidth
                                multiline
                                rows={4}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                                helperText={<ErrorMessage name="description" />}
                                error={touched.description && Boolean(errors.description)}
                            />
                            <InputLabel htmlFor="renewUser" style={{ color: 'white' }}>Renew User?</InputLabel>
                            <Field
                                as={Switch}
                                name="renewUser"
                                id="renewUser"
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default WalletForm;
