import React, { useEffect, useState } from 'react';
import './dashboard.css';
import ErrorPage from '../ErrorPage/error';
import Sidebar from '../../components/SideBar/SideBar';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import DefaultPage from '../DefaultPage/DefaultPage';
import Settings from '../Settings/settings';

import Raffle from '../Raffles/raffle';
import NotificationComponent from '../../components/Notification/Notification';
import AdminPage from '../AdminPage/adminpage';
import { WHITELIST } from '../../constantes/constants';
import WalletRegister from '../WalletRegisterPage/walletRegister';

const useStyles = makeStyles({
    root: {
        borderColor: 'red',
        fontSize: '0.8rem',
    },
});



const canLogin = async (wallet: string) => {
    try {
        const response = await fetch(`https://the-systems.onrender.com/checkElegibity?wallet=${wallet}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.check;
    } catch (error) {
        console.error("Não foi possível verificar a elegibilidade:", error);
        return false;
    }
};

function Dashboard() {
    const queryParams = new URLSearchParams(window.location.search);
    const wallet = queryParams.get('wallet');
    const [canLogged, setCanLogged] = useState(false);
    const [isVerifying, setIsVerifying] = useState(true);
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [showNotification, setShowNotification] = useState<boolean>(false);

    const handleNotificationClose = () => {
        setShowNotification(false);
    };
    useEffect(() => {
        const verifyWallet = async () => {
            if (wallet) {
                const loggedIn = await canLogin(wallet);
                setCanLogged(loggedIn);
                setIsVerifying(false);
                setShowNotification(true);
                setNotification({ message: "Welcome!!!, have a good use!", type: 'success' });
            } else {
                setIsVerifying(false);
            }
        };
        verifyWallet();

    }, [wallet]);

    const handleLogout = () => {
        window.location.href = '/';
    };

    const [page, setPage] = useState('default');

    const receiveDataFromSidebar = (data: any) => {
        setPage(data.message);
    };

    let componentPage: JSX.Element;
    switch (page) {
        case 'raffles':
            componentPage = <Raffle />;
            break;
        case 'adminPanel':
            componentPage = <AdminPage />;
            break;
        case 'settings':
            componentPage = <Settings />;
            break;
        case 'walletRegister':
            componentPage = <WalletRegister />;
            break;
        default:
            componentPage = <Raffle />;
            break;
    }

    const style = useStyles();

    if (isVerifying) {
        return <div></div>;
    } else if (canLogged) {
        return (
            <div className="Dashboard">
                <Sidebar sendDataToDashboard={receiveDataFromSidebar} />
                <div className="elipse-container-dashboard"></div>
                <div className="dashboard-body">
                    <div className="square-page">{componentPage}</div>
                </div>
                <div className="user-container">
                    <Button
                        variant="outlined"
                        className={style.root}
                        style={{

                            color: 'white',
                            borderRadius: '20px',
                            borderColor: 'red',
                            cursor: 'pointer',
                            fontFamily: 'Chillax',
                            transition: 'background-color 0.3s, border-color 0.3s',
                        }}
                        onClick={handleLogout}
                        onMouseEnter={(e) => {
                            (e.target as HTMLButtonElement).style.backgroundColor = 'red';
                            (e.target as HTMLButtonElement).style.borderColor = 'darkred';
                        }}
                        onMouseLeave={(e) => {
                            (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
                            (e.target as HTMLButtonElement).style.borderColor = 'red';
                        }}
                    >
                        LogOut
                    </Button>

                </div>
                {showNotification && (
                    <NotificationComponent
                        message={notification.message}
                        severity={notification.type as 'error' | 'warning' | 'info' | 'success'}
                        open={showNotification}
                        handleClose={handleNotificationClose}
                    />
                )}
            </div>
        );
    } else {
        return <ErrorPage />;
    }
}

export default Dashboard;
