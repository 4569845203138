import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select'; // Importando SelectChangeEvent
import { makeStyles } from '@mui/styles';
import './FilterDropdown.css';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
    },
    dropdown: {
        marginRight: '10px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        }
    },
    input: {
        marginRight: '10px',
        color: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        }
    },
    button: {
        marginTop: '10px',
    }
});

interface FilterDropdownProps {
    onFilter: (filters: { chain: string[], status: string[], search: string, clickedFilter: Boolean }) => void;
    onCleanFilter: (filters: { clickedClean: Boolean }) => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ onFilter, onCleanFilter }) => {
    const classes = useStyles();
    let [statusFilters, setStatusFilters] = useState<string[]>([]);
    const [chainFilters, setChainFilters] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [clickedClean, setClickedClean] = useState<Boolean>(false);
    const [clickedFilter, setClickedFilter] = useState<Boolean>(false);

    const handleFilter = () => {
        onFilter({ chain: chainFilters, status: statusFilters, search: searchInput, clickedFilter: clickedFilter });
    };
    const handleCleanFilter = () => {
        setStatusFilters([])
        setSearchInput('')
        setChainFilters([])
        setClickedClean(true)
        onCleanFilter({ clickedClean: clickedClean })
    };

    const handleStatusChange = (event: SelectChangeEvent<string | string[]>) => {
        const { value } = event.target;
        const newStatusFilters = Array.isArray(value) ? value : [value];
        setStatusFilters(newStatusFilters);
        setClickedFilter(true)
    };

    const handleChainChange = (event: SelectChangeEvent<string | string[]>) => {
        const { value } = event.target;
        const newChainFilters = Array.isArray(value) ? value : [value];
        setChainFilters(newChainFilters);
    };

    return (
        <div className={classes.root}>
            <p className="title-info">
                Status:
            </p>
            <Select
                className={classes.dropdown}
                multiple
                value={statusFilters}
                onChange={handleStatusChange}
                renderValue={(selected: string[]) => []}
                input={<Input />}
                sx={{
                    color: 'white',
                }}
            >
                <MenuItem value="Registered">
                    <Checkbox checked={statusFilters.includes("Registered")} />
                    Registered
                </MenuItem>
                <MenuItem value="Discord Requirement">
                    <Checkbox checked={statusFilters.includes("Discord Requirement")} />
                    Discord Requirement
                </MenuItem>
                <MenuItem value="Wallet Requirement">
                    <Checkbox checked={statusFilters.includes("Wallet Requirement")} />
                    Wallet Requirement
                </MenuItem>
                <MenuItem value="Invalid Password">
                    <Checkbox checked={statusFilters.includes("Invalid Password")} />
                    Invalid Password
                </MenuItem>
                <MenuItem value="Pending">
                    <Checkbox checked={statusFilters.includes("Pending")} />
                    Pending
                </MenuItem>
                <MenuItem value="Premium Raffle (Need Manual)">
                    <Checkbox checked={statusFilters.includes("Premium Raffle (Need Manual)")} />
                    Premium Raffle
                </MenuItem>
            </Select>
            <p className="title-info">
                Chain:
            </p>
            <Select
                className={classes.dropdown}
                multiple
                value={chainFilters}
                onChange={handleChainChange}
                renderValue={(selected: string[]) => []}
                input={<Input />}
                sx={{
                    color: 'white',
                }}
            >
                <MenuItem value="eth">
                    <Checkbox checked={chainFilters.includes("eth")} />
                    ETH
                </MenuItem>
                <MenuItem value="btc">
                    <Checkbox checked={chainFilters.includes("btc")} />
                    BTC
                </MenuItem>
                <MenuItem value="arb">
                    <Checkbox checked={chainFilters.includes("arb")} />
                    ARB
                </MenuItem>
                <MenuItem value="sol">
                    <Checkbox checked={chainFilters.includes("sol")} />
                    SOL
                </MenuItem>
                <MenuItem value="sei">
                    <Checkbox checked={chainFilters.includes("sei")} />
                    SEI
                </MenuItem>
                <MenuItem value="matic">
                    <Checkbox checked={chainFilters.includes("matic")} />
                    MATIC
                </MenuItem>
                <MenuItem value="blast">
                    <Checkbox checked={chainFilters.includes("blast")} />
                    BLAST
                </MenuItem>
            </Select>
            <p className="title-info">
                Name:
            </p>
            <Input
                className={classes.input}
                placeholder="Search..."
                value={searchInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
                sx={{
                    color: 'white',
                    '& .MuiInputBase-input': {
                        color: 'white',
                        textAlign: 'center',
                    },
                }}
                inputProps={{
                    style: { textAlign: 'center' }
                }}
            />
            <Button
                type="submit"
                variant="outlined"
                onClick={handleFilter}
                sx={{
                    color: '#FF6D03',
                    borderColor: '#FF6D03',
                    ':hover': {
                        borderColor: 'white',
                        backgroundColor: 'transparent'
                    },
                }}>
                Filter
            </Button>
            <Button
                type="submit"
                variant="outlined"
                onClick={handleCleanFilter}
                sx={{
                    color: 'red',
                    borderColor: 'red',
                    marginLeft: '6px',
                    minWidth: '10px',
                    ':hover': {
                        borderColor: 'white',
                    },
                }}>
                X
            </Button>
        </div>
    );
};

export default FilterDropdown;
