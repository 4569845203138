import React from 'react';
import './Home.css';
import Navbar from '../../components/NavBar/NavBar.';
import robotImage from '../../assets/robotImage.png';
import PrincipalSection from './PrincipalSection/PrincipalSection';

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <Navbar />
      </header>
      <section className="Home-body">
        <PrincipalSection />
        <div>
          <img src={robotImage} alt="Robot" className='homeImg' />
          <div className='elipse-container'>
            <p className='elipse elipse-left'></p>
            <p className='elipse elipse-center'></p>
            <p className='elipse elipse-right'></p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
