import React, { useState } from 'react';
import './connectButton.css';
import { COMPASS_WALLET } from "@sei-js/core";
import { useHistory } from 'react-router-dom';
import { useQueryClient, useSigningCosmWasmClient as useSigningCommWasmClient } from '@sei-js/react';
const chainId = 'atlantic-2';

const ConnectButton = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory();
    const canLogin = async (wallet: string) => {
        try {
            const response = await fetch(`https://the-systems.onrender.com/checkElegibity?wallet=${wallet}`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.check;
        } catch (error) {
            console.error("Não foi possível verificar a elegibilidade:", error);
            return false;
        }
    };


    const handleConnect = async () => {
        setLoading(true);
        try {
            await COMPASS_WALLET.connect(chainId);
            const accounts = await COMPASS_WALLET.getAccounts(chainId);
            await COMPASS_WALLET.disconnect(chainId);
            setSuccess(true);
            setTimeout(() => {
                canLogin(accounts[0].address).then((isEligible: any) => {
                    if (isEligible) {
                        history.push(`/dashboard?wallet=${accounts[0].address}`);
                    } else {
                        history.push(`/dashboard`);
                    }
                });
            }, 2000);
        } catch (error) {
            console.error("Erro ao conectar à Compass Wallet:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <button onClick={handleConnect} disabled={loading || success} className={`connect-button ${success ? 'success' : ''}`}>
            {loading ? <div className="loader"></div> : success ? 'Success' : 'Connect'}
        </button>
    );
};

export default ConnectButton;
