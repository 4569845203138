import React from 'react';
import './NavBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faDiscord, faReddit, faTwitter } from '@fortawesome/free-brands-svg-icons';
import ConnectButton from '../ConnectButton/connectButton';
import DiscordLoginButton from '../DiscordLogin/DiscordLoginButton';

const Navbar = () => {
    return (
        <nav className='navbar-container'>
            <div className="nav-logo">The Systems</div>
            <ul className="nav-links">
                <li><a href="#">Home</a></li>
                <li><a href="#">RoadMap</a></li>
                <li><a href="#">Raffles</a></li>
                {/* <li><a href="#">Mint</a></li>
                <li><a href="#">Sniper</a></li> */}
            </ul>
            <ConnectButton />
            
            
            <div className="social-icons">
                <a href="#"><FontAwesomeIcon icon={faGithub} color='white' /></a>
                <a href="#"><FontAwesomeIcon icon={faDiscord} color='white' /></a>
                <a href="#"><FontAwesomeIcon icon={faReddit} color='white' /></a>
                <a href="#"><FontAwesomeIcon icon={faTwitter} color='white' /></a>
            </div>
        </nav>
    );
};

export default Navbar;
