import { Box, Button, Divider, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { IWalletEntry } from '../AdminPage/WalletInterface';
import { useState, useEffect } from 'react';

interface WalletListProps {
  entries: IWalletEntry[];
  onEdit: (entry: IWalletEntry) => void;
  onRemove: (id: string) => void;
  onApprove: (id: string, code: string) => void;
}

const WalletList: React.FC<WalletListProps> = ({ entries, onEdit, onRemove, onApprove }) => {
  const [havePermission, setHavePermission] = useState(false);
  const [updatedEntries, setUpdatedEntries] = useState<IWalletEntry[]>(entries); // Estado local para gerenciar as entradas atualizadas
  const queryParams = new URLSearchParams(window.location.search);
  const wallet = queryParams.get('wallet');

  useEffect(() => {
    setUpdatedEntries(entries);
  }, [entries]);


  const onApproveInputChange = (id: string, value: string) => {
    const updatedEntriesCopy = entries.map((entry) => {
      if (entry.id === id) {
        return {
          ...entry,
          approveCode: value
        };
      }
      return entry;
    });

    setUpdatedEntries(updatedEntriesCopy);
  };

  useEffect(() => {
    const verifyWallet = async () => {
      if (wallet) {
        setHavePermission(await checkPermission(wallet))
      } else {
        setHavePermission(false);
      }
    };
    verifyWallet();
  }, [wallet]);

  const checkPermission = async (wallet: string) => {
    try {
      const response = await fetch(`https://the-systems.onrender.com/checkApprovePermission?walletRegister=${wallet}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.permission;
    } catch (error) {
      console.error("Não foi possível verificar a elegibilidade:", error);
      return false;
    }
  };


  const listItemSx = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bgcolor: 'transparent',
    borderBottom: '1px solid #999',
  };

  const buttonSx = {
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'transparent', py: 1 }}>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>Wallet Address</Typography>
        <Typography variant="subtitle1" sx={{ width: '100px', textAlign: 'center' }}>Duration</Typography>
        <Typography variant="subtitle1" sx={{ width: '100px', textAlign: 'center' }}>Status</Typography>
        <Typography variant="subtitle1" sx={{ width: '200px', textAlign: 'center' }}>Actions</Typography>
      </Box>
      <Divider />
      <List sx={{ padding: 0 }}>
        {updatedEntries.map(entry => (
          <ListItem key={entry.id} sx={listItemSx}>
            <Typography variant="body1" sx={{ flex: 1, wordBreak: 'break-all' }}>{entry.wallet}</Typography>
            <Typography variant="body2" sx={{ width: '100px', textAlign: 'center' }}>{entry.duration}</Typography>
            <Typography variant="body2" sx={{ width: '100px', textAlign: 'center' }}>
              {entry.approved ? "Approved" : "In Approve"}
            </Typography>
            <Box sx={{ width: '200px', textAlign: 'center' }}>
              <Button variant="outlined" disabled={entry.approved} onClick={() => onEdit(entry)} sx={buttonSx}>
                Edit
              </Button>
              {!entry.approved &&
                < Button variant="outlined" onClick={() => onRemove(entry.wallet)} sx={buttonSx}>
                  Remove
                </Button>}
              {!entry.approved && havePermission && (
                <>
                  <Button variant="outlined" onClick={() => onRemove(entry.wallet)} sx={buttonSx}>
                    Remove
                  </Button>
                  <TextField
                    type="string"
                    variant="outlined"
                    value={entry.approveCode}
                    onChange={(e) => onApproveInputChange(entry.id, e.target.value)}
                    sx={{ marginRight: '8px', width: '80px' }}
                  />
                  <Button variant="outlined" onClick={() => onApprove(entry.wallet, entry.approveCode)} sx={buttonSx}>
                    Approve
                  </Button>
                </>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box >
  );
};

export default WalletList;