import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home/Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage/error';
import Dashboard from './pages/Dashboard/dashboard';


require('dotenv').config();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard/:info?" exact component={Dashboard}/>
        <Route path="/error" component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);
