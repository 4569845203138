import React, { useEffect, useState } from 'react';
import './PrincipalSection.css';

const PrincipalSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className={`items-container ${isVisible ? 'fade-up' : ''}`}>
            <h1 className='title-item'>All in one SYSTEM</h1>
            <h2 className='subtitle-item'>Made for the Community</h2>
            <p className='text-item'>
            With intelligent algorithms and real-time data analysis, our tools optimize performance and maximize your results. Join us and unleash the potential of automated working with our state-of-the-art tools technology.
            </p>
            <div className="button-wrapper">
                <button className='button-item'>Get started</button>
            </div >
        </div>
    );
};

export default PrincipalSection;
