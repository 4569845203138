import React, { useEffect, useState } from 'react';
import WalletRegisterList from '../CrudPrincipal/walletRegisterList';
import WalletRegisterForm from '../CrudPrincipal/walletRegisterForm';

interface Wallet {
  name: string;
  wallet: string;
}
const WalletRegisterPrincipalOrdinal: React.FC = () => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState<{ name: string; wallet: string }>({ name: '', wallet: '' });
  const queryParams = new URLSearchParams(window.location.search);
  const walletUser = queryParams.get('wallet');

  const handleAddOrUpdateWallet = async (walletData: Wallet) => {
    await addNewWallet(walletData)
    await getWallets(walletUser)
  };

  const addNewWallet = async (wallet: any) => {
    try {
      const response = await fetch('https://the-systems.onrender.com/walletRegister', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ wallet: wallet.wallet, name: wallet.name, chain: 'ordinal', walletUser: walletUser }),
      });

      const data = await response.json();

    } catch (err) {
      console.error(err)
    }
  }

  const removeWallet = async (wallet: any) => {
    try {
      const response = await fetch('https://the-systems.onrender.com/removeWallet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ wallet: wallet.wallet, chain: 'ordinal', walletUser: walletUser }),
      });

      const data = await response.json();

    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    getWallets(walletUser)
  }, [])

  const getWallets = async (walletUser: any) => {
    try {
      const response = await fetch(`https://the-systems.onrender.com/getWalletList?wallet=${walletUser}&chain=ordinal`);
      const data = await response.json();

      if (response.ok) {
        if (data.wallets.length > 0) {
          setWallets(data.wallets)
        }

      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleDeleteWallet = async (wallet: Wallet) => {
    await removeWallet(wallet)
    await getWallets(walletUser)
  };

  return (
    <div>
      <WalletRegisterForm
        onSubmit={handleAddOrUpdateWallet}
        editingIndex={editingIndex}
        wallets={wallets}
        formData={formData}
        setFormData={setFormData}
      />
      <div className="list-container" style={{ marginLeft: '1%', height: '25vh', marginTop: '2vh' }}>
        <WalletRegisterList
          wallets={wallets}
          onDelete={handleDeleteWallet}
        />
      </div>
    </div>
  );
};

export default WalletRegisterPrincipalOrdinal;
