import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Box } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

interface WalletFormProps {
  onSubmit: (data: { name: string; wallet: string }) => void;
  editingIndex: number | null;
  wallets: { name: string; wallet: string }[];
  formData: { name: string; wallet: string };
  setFormData: React.Dispatch<React.SetStateAction<{ name: string; wallet: string }>>;
}

const WalletRegisterForm: React.FC<WalletFormProps> = ({ onSubmit, editingIndex, wallets, formData, setFormData }) => {
  const initialValues = {
    name: '',
    wallet: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Nome é obrigatório'),
    wallet: Yup.string().required('Wallet é obrigatória'),
  });

  useEffect(() => {
    if (editingIndex !== null && wallets[editingIndex]) {
      const { name, wallet } = wallets[editingIndex];
      setFormData({ name, wallet });
    } else {
      setFormData(initialValues);
    }
  }, [editingIndex, wallets]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, actions) => {
          onSubmit(values);
          setFormData(initialValues);
          actions.resetForm();
        }}

      >
        {({ errors, touched, values }) => (
          <Form>
            <Box sx={{ '& .MuiTextField-root': { m: 1 }, mt: 3 }}>
              <TextField
                name="name"
                label="Nome"
                fullWidth
                value={values.name}
                onChange={handleInputChange}
                InputProps={{
                  style: { color: 'white' },
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                }}
                helperText={(touched.name && !formData.name) && errors.name}
                error={touched.name && !formData.name}
              />

              <TextField
                name="wallet"
                label="Wallet"
                fullWidth
                value={values.wallet}
                onChange={handleInputChange}
                InputProps={{
                  style: { color: 'white' },
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                }}
                helperText={(touched.wallet && !formData.wallet) && errors.wallet}
                error={touched.wallet && !formData.wallet}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
              <Button type="submit" variant="contained" color="primary">
                {editingIndex !== null ? 'Atualizar' : 'Adicionar'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default WalletRegisterForm;
