import React, { useState } from 'react';
import DefaultPage from '../DefaultPage/DefaultPage';
import Alphabot from './Alphabot/alphabot';
import Atlas from './Atlas/atlas';

const Raffle = () => {
    const [selectedItem, setSelectedItem] = useState('alphabot');

    const handleButtonClick = (item: string) => {
        setSelectedItem(item);
    };
    
    let componentPage: JSX.Element;
    switch (selectedItem) {
        case 'alphabot':
            componentPage = <Alphabot />;
            break;
        // case 'atlas':
        //     componentPage = <Atlas />;
        //     break;

        default:
            componentPage = <DefaultPage />;
            break;
    }

    return (
        <div style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            fontFamily: 'Chillax',
            alignItems: 'center'
        }}>
            <h2>Raffles</h2>
            <div className='setting-container'>
                <button className={selectedItem === 'alphabot' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('alphabot')}>Alphabot</button>
                {/* <button className={selectedItem === 'atlas' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('atlas')}>Atlas</button> */}
                {/* <button disabled className={selectedItem === 'soon' ? 'setting-item selected' : 'setting-item'} onClick={() => handleButtonClick('soon')}>Soon?</button> */}
            </div>
            <hr style={{ width: '50%', marginTop: '20px', border: '1px solid #313131' }} />

            <div>
                {componentPage}
            </div>
        </div>
    );
};

export default Raffle;