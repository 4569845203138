import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import WalletForm from './WalletForm';
import WalletList from './WalletList';
import { IWalletEntry } from './WalletInterface';

function AdminPage() {
    const [entries, setEntries] = useState<IWalletEntry[]>([]);
    const [editingEntry, setEditingEntry] = useState<IWalletEntry | null>(null);
    const queryParams = new URLSearchParams(window.location.search);
    const wallet = queryParams.get('wallet');

    const handleEdit = (entry: IWalletEntry) => {
        setEditingEntry(entry);
    };

    const handleSubmit = async (entry: IWalletEntry) => {
        if (editingEntry) {
            setEntries(entries.map(e => e.id === entry.id ? entry : e));
        } else {
            setEntries([...entries, { ...entry, id: Date.now().toString() }]);
        }
        setEditingEntry(null);

        await saveRequest(entry)
        await listRequest()
    };

    const saveRequest = async (entry: any) => {
        try {
            const response = await fetch('https://the-systems.onrender.com/saveNewRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...entry, walletRegister: wallet }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message);
            }

        } catch (error: any) {
        }
    }
    useEffect(()=>{
        setInterval(()=>{
            listRequest()
        }, 20000)
        listRequest()
    },[])
    const listRequest = async () => {
        try {
            const response = await fetch(`https://the-systems.onrender.com/listRequest?walletRegister=${wallet}`);
            const data = await response.json();

            if (response.ok) {
                const requests = data.requests.map((request: any) => {
                    return {
                        id: request.id,
                        wallet: request.wallet,
                        duration: request.duration,
                        urlComprove: request.urlProof,
                        description: request.description,
                        approved: request.status === "Approved",
                        approveCode: '',
                        discordName: request.discordName
                    }
                })
                setEntries(requests)
            }

        } catch (error: any) {
        }
    };

    const removeEntry = async (id: string) => {
        await removeRequest(id)
    };

    const removeRequest = async (wallet: any)=>{
        try {
            const response = await fetch('https://the-systems.onrender.com/removeRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({wallet: wallet }),
            });

            const data = await response.json();
            await listRequest()
            if (!response.ok) {
                throw new Error(data.message);
            }

        } catch (error: any) {
        }
    }

    const approveEntry = async (id: string, code: string) => {
        await approveRequest(id, code)
    };

    const approveRequest = async (id: string, code: string) => {

        try {
            const response = await fetch('https://the-systems.onrender.com/approveRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ walletRegister: wallet, wallet: id, code: code,  }),
            });

            const data = await response.json();
            await listRequest()
            if (!response.ok) {
                throw new Error(data.message);
            }

        } catch (error: any) {
        }

    }

    return (
        <div className='RaffleContainer'>
            <div className="filterCentralized">
                <WalletForm onSubmit={handleSubmit} initialData={editingEntry} />
            </div>
            <hr style={{ width: '95%', marginTop: '20px', border: '1px solid #313131' }} />

            <div className="list-container" style={{ marginLeft: '7%', height: '25vh' }}>
                <WalletList entries={entries} onEdit={handleEdit} onRemove={removeEntry} onApprove={approveEntry} />
            </div>
        </div>
    );
}
export default AdminPage;