import React, { useState } from 'react';
import DefaultPage from '../../DefaultPage/DefaultPage';
import AlphabotForm from './alphabotForm/alphabotForm';
import './rafflesForm.css';
import AtlasForm from './atlasForm/atlasForm';

const RafflesForm = () => {
    const [selectedItem, setSelectedItem] = useState('alphabot');

    const handleButtonClick = (item: string) => {
        setSelectedItem(item);
    };
    
    let componentPage: JSX.Element;
    switch (selectedItem) {
        case 'alphabot':
            componentPage = <AlphabotForm />;
            break;
        // case 'atlas':
        //     componentPage = <AtlasForm />;
        //     break;
        // case 'sniper':
        //     componentPage = <SniperForm />;
        //     break;
        default:
            componentPage = <DefaultPage />;
            break;
    }

    return (
        <div style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            fontFamily: 'Chillax',
            alignItems: 'center'
        }}>
            <div className='raffles-container'>
                <button className={selectedItem === 'alphabot' ? 'raffle-item selected' : 'raffle-item'} onClick={() => handleButtonClick('alphabot')}>Alphabot</button>
                {/* <button className={selectedItem === 'atlas' ? 'raffle-item selected' : 'raffle-item'} onClick={() => handleButtonClick('atlas')}>Atlas</button> */}
                {/* <button className={selectedItem === 'subber' ? 'raffle-item selected' : 'raffle-item'} onClick={() => handleButtonClick('mint')}>Subber</button> */}
            </div>
            <div>
                {componentPage}
            </div>
        </div>
    );
};

export default RafflesForm;